import request, { useAuthToken } from '../client'
import { loadList, clearList } from './watchlist'

const ACTION_CURRENCY = 'APP/CURRENCY'
const ACTION_LANGUAGE = 'APP/LANGUAGE'
const ACTION_ODOMETER = 'APP/ODOMETER'
const ACTION_UPDATE_BID_SLOT = 'APP/BID_SLOT/UPDATE'

export const ACTION_CONFIG = 'APP/CONFIG'
export const ACTION_USER = 'APP/USER'

const STORAGE_AUTH_TOKEN = 'token'

const initState = {
    loading: true,
    language: 'bg',
    currency: 'BGN',
    odometer: {
        suffix: ' km',
        rate: 1.609
    },
    fees: null,
    user: null
}

export default function(state=initState, {type,payload}) {
    switch(type) {
        case ACTION_CURRENCY:
            return {
                ...state,
                currency: payload
            }
        case ACTION_LANGUAGE:
            return {
                ...state,
                language: payload
            }
        case ACTION_CONFIG:
            return {
                ...state,
                loading: false,
                fees: payload.fees,
                user: payload.user
            }
        case ACTION_USER:
            return {
                ...state,
                user: payload
            }
        case ACTION_UPDATE_BID_SLOT:
            return {
                ...state,
                user: {
                    ...state.user,
                    bid_slots: state.user.bid_slots.map(slot=>{
                        if(slot.id === payload.id) {
                            return {
                                ...slot,
                                ...payload
                            }
                        }
                        return slot
                    })
                }
            }
        default:
            return state;
    }
}

export function changeCurrency(currency) {
    return dispatch => {
        dispatch({
            type: ACTION_CURRENCY,
            payload: currency
        })
    }
}

export function changeLanguage(lang) {
    return dispatch => {
        dispatch({
            type: ACTION_LANGUAGE,
            payload: lang
        })
    }
}

export function changeOdometer(unit) {
    return {
        type: ACTION_ODOMETER,
        payload: unit
    }
}

export function loadConfig() {
    return async dispatch => {
        const authToken = window.localStorage.getItem(STORAGE_AUTH_TOKEN);
        const payload = {
            user: null
        }
        if(authToken) {
            useAuthToken(authToken)
            try {
                const { accessToken, user } = await request('/user/verify', {
                    method: 'POST'
                });
                payload.user = user;
                window.localStorage.setItem(STORAGE_AUTH_TOKEN, accessToken);
            } catch(e) {
                useAuthToken(null)
                window.localStorage.removeItem(STORAGE_AUTH_TOKEN);
            }
        }
        
        const config = await request('/config', {
            method: 'GET'
        });
        
        dispatch({
            type: ACTION_CONFIG,
            payload: {
                ...config,
                ...payload
            }
        })

        if(payload.user) {
            dispatch(loadList(payload.user.id))
        }
    }
}

export function setUser(user, token) {
    return dispatch=>{
        useAuthToken(token)
        window.localStorage.setItem(STORAGE_AUTH_TOKEN, token)
        dispatch({
            type: ACTION_USER,
            payload: user
        })
        if(user) {
            dispatch(loadList(user.id))
        }
    }
}

export function clearUser() {
    return dispatch => {
        useAuthToken(null)
        window.localStorage.removeItem(STORAGE_AUTH_TOKEN)
        dispatch({
            type: ACTION_USER,
            payload: null
        })
        dispatch(clearList())
    }
}

export function updateBidSlot(slot) {
    return {
        type: ACTION_UPDATE_BID_SLOT,
        payload: slot
    }
}