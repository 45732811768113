import client from '../client'

const ACTION_LOADING = 'APP/FACETS/LOADING';
const ACTION_LOAD = 'APP/FACETS/LOAD'

const initState = {
    loading: false,
    loaded: false,
    data: {}
}
export default function (state = initState, { type, payload }) {
    switch (type) {
        case ACTION_LOADING:
            return {
                ...state,
                loading: payload,
                loaded: payload ? false : state.loaded
            }
        case ACTION_LOAD:
            return {
                ...state,
                loading: false,
                loaded: true,
                data: payload
            }
        default:
            return state;
    }
}

export function loadFacets(refresh = false) {
    return async (dispatch, getState) => {
        const { loading, loaded } = getState().facets;

        if (!refresh && (loading || loaded)) {
            return;
        }

        dispatch({
            type: ACTION_LOADING,
            payload: true
        })

        const facets = await client('/search/facets', {
            method: 'GET',
            query: {
                include: ['brand', 'year', 'fuel_type', 'damage', 'color', 'body']
            }
        })

        dispatch({
            type: ACTION_LOAD,
            payload: facets
        })

    }
}