import React from 'react'

import loader from '../imgs/loader.mp4'

export default class Loader extends React.PureComponent {

    render() {
        return (
            <div style={{display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:0, left:0, right:0, bottom:0, background:'#fff'}}>
                <video width="400" height="254" muted autoPlay loop>
                    <source src={loader} type="video/mp4"/>
                    Loading...
                </video>
            </div>
        )
    }
}