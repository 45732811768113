import request from '../client'
import moment from 'moment'

const ACTION_ADDING = 'WATCHLIST/ADDING',
    ACTION_ADD = 'WATCHLIST/ADD',
    ACTION_REMOVE = 'WATCHLIST/REMOVE',
    ACTION_LOADING = 'WATCHLIST/LOADING',
    ACTION_LIST = 'WATCHLIST/LOADED',
    ACTION_CLEAR = 'WATCHLIST/CLEAR'

const initState = {
    vehicles: {},
    loading: false,
    totalActive: 0
}

function actionList(state, payload) {
    let vehicles = {}, active = 0;
    payload.vehicles.forEach(({ vehicle_id, sale_date })=>{
        if(moment(sale_date).isAfter(moment())) {
            active++;
        }
        vehicles[vehicle_id] = {
            status: 'resolved'
        }
    })
    return {
        ...state,
        loading: false,
        vehicles,
        totalActive: active
    }
}

export default function (state = initState, { type, payload }) {
    switch (type) {
        case ACTION_LOADING:
            return {
                ...state,
                loading: payload
            }
        case ACTION_LIST:
            return actionList(state, payload)
        case ACTION_CLEAR:
            return {
                ...state,
                vehicles: {},
                totalActive: 0
            }
        case ACTION_ADDING:
            return {
                ...state,
                totalActive: state.totalActive + (moment(payload.sale_date).isAfter(moment()) ? 1 : 0),
                vehicles: {
                    ...state.vehicles,
                    [payload.id]: {
                        status: 'pending'
                    }
                }
            }
        case ACTION_ADD:
            return {
                ...state,
                vehicles: {
                    ...state.vehicles,
                    [payload.id]: {
                        ...state.vehicles[payload.id],
                        status: 'resolved'
                    }
                }
            }
        case ACTION_REMOVE:
            const vehicles = {
                ...state.vehicles
            }
            delete vehicles[payload.id]
            return {
                ...state,
                vehicles,
                totalActive: state.totalActive - (moment(payload.sale_date).isAfter(moment()) ? 1 : 0)
            }
        default:
            return state;
    }
}

export function loadList(userId) {
    return async (dispatch, getState) => {
        if(!userId) {
            userId = getState().app.user.id;
        }
        dispatch({
            type: ACTION_LOADING,
            payload: true
        })
        try {
            const payload = await request(`/user/${userId}/watchlist`, {
                method: 'GET'
            });
            dispatch({
                type: ACTION_LIST,
                payload
            })
        } catch (e) {
            dispatch({
                type: ACTION_LOADING,
                payload: false
            })
        }
    }
}

export function addVehicle(vehicle, watchlistId) {
    return async (dispatch, getState)=>{
        dispatch({
            type: ACTION_ADDING,
            payload: vehicle
        })
        try {
            request(`/user/${getState().app.user.id}/watchlist/${watchlistId ? watchlistId : 'default'}/${vehicle.id}`, {
                method: "POST"
            })
            dispatch({
                type: ACTION_ADD,
                payload: vehicle
            })
        } catch(e) {
            dispatch({
                type: ACTION_REMOVE,
                payload: vehicle
            })
        }
    }
}

export function deleteVehicle(vehicle, watchlistId) {
    return async (dispatch, getState)=>{
        dispatch({
            type: ACTION_REMOVE,
            payload: vehicle
        })
        try {
            await request(`/user/${getState().app.user.id}/watchlist/${watchlistId ? watchlistId : 'default'}/${vehicle.id}`, {
                method: 'DELETE'
            })
        } catch(e) {}
    }
}

export function clearList() {
    return {
        type: ACTION_CLEAR
    }
}