import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'
import { loadConfig } from '../redux/app'

import { trackPage } from '../tracking';

import LoaderCmp from '../components/Loader'

const loading = () => <LoaderCmp/>;

const Home = Loadable({ loader: () => import('./home'), loading })
const Search = Loadable({ loader: () => import('./search'), loading })

const VehicleLoaderVin = Loadable({ loader: () => import('../components/VehicleLoaderVin'), loading })
const VehicleRoutes = Loadable({ loader: () => import('./vehicle/routes'), loading })
const VehicleRoutesVinCmp = props => <VehicleLoaderVin {...props} component={VehicleRoutes} />

const HowItWorks = Loadable({ loader: () => import('./about/how'), loading })
const Faq = Loadable({ loader: () => import('./about/faq'), loading })
const Contacts = Loadable({ loader: () => import('./about/contacts'), loading })
const Login = Loadable({ loader: () => import('./sign/login'), loading })
const Register = Loadable({ loader: () => import('./sign/register'), loading })
const Account = Loadable({ loader: () => import('./account'), loading })

class Routes extends React.PureComponent {

    componentWillMount() {
        this.props.init();
    }

    render() {
        if(!this.props.ready) {
            return <LoaderCmp/>
        }
        return (
            <BrowserRouter>
                <Route component={GaRoute} />
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/login" exact component={Login} />
                    <Route path="/register" exact component={Register} />
                    <Route path="/account" component={Account} />
                    <Route path="/how-it-works" exact component={HowItWorks} />
                    <Route path="/faq" exact component={Faq} />
                    <Route path="/faq/:topicId" exact component={Faq} />
                    <Route path="/contacts" exact component={Contacts} />
                    <Route path="/cars" exact component={Search} />
                    <Route path="/:brand" exact component={Search} />
                    <Route path="/:brand/:model/:vin" render={VehicleRoutesVinCmp} />
                    <Route component={NotFound}/>
                </Switch>
            </BrowserRouter>
        )
    }
}

export default connect(state=>({
    ready: !state.app.loading
}), dispatch=>({
    init: () => dispatch(loadConfig())
}))(Routes);

class GaRoute extends React.PureComponent {

    _trackPage = page => {
        window.scrollTo(0, 0);
        trackPage(page)
    }

    componentDidMount() {
        this._trackPage(this.props.location.pathname + this.props.location.search)
    }

    componentDidUpdate(prevProps) {
        const currentPage = prevProps.location.pathname + prevProps.location.search;
        const nextPage = this.props.location.pathname + this.props.location.search;

        if (currentPage !== nextPage) {
            this._trackPage(nextPage)
        }
    }

    render() {
        return null;
    }
}

class NotFound extends React.PureComponent {

    render() {
        const { location } = this.props;
        return <Redirect to={`/?nf=${encodeURIComponent(`${location.pathname}${location.search}`)}`}/>
    }
}