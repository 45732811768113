import GoogleAnalytics from 'react-ga'

const ENABLED = process.env.REACT_APP_TRACKING === 'true' ? true : false
const fbq = window.fbq;

export function trackPage(page) {
    if(ENABLED) {
        GoogleAnalytics.pageview(page)
        fbq('track', 'PageView');
    }
    
}

export function trackBrands(brands) {
    if(ENABLED) {
        brands.forEach(({ facetValue })=>{
            fbq('track', 'ViewContent', {
                content_category: facetValue
            })
        })
    }
}

export function trackCarsList(list) {
    if(ENABLED) {
        fbq('track', 'ViewContent', {
            content_category: 'Vehicles',
            content_type: 'product',
            content_ids: list.map(({ id })=>id)
        })
    }
}

export function trackVehicle(vehicle) {
    if(ENABLED) {
        fbq('track', 'ViewContent', {
            content_category: 'Vehicle',
            content_type: 'product',
            content_ids: [vehicle.id]
        })
    }
}

export function trackVehicleOffer(vehicle, state) {
    if(!ENABLED) {
        return;
    }
    switch(state) {
        case 'start':
            fbq('track', 'Lead', {
                content_type: 'product',
                content_ids: [vehicle.id]
            })
            GoogleAnalytics.event({
                category: 'User',
                action: 'Interested'
            })
            break;
        case 'finish':
            fbq('track', 'Contact', {
                content_type: 'product',
                content_ids: [vehicle.id]
            })
            GoogleAnalytics.event({
                category: 'User',
                action: 'Ask for offer'
            })
            break;
        default:
            break;
    }
}

export function trackNotifications(status) {
    if(ENABLED) {
        GoogleAnalytics.event({
            category: 'User',
            action: 'Notifications',
            label: status
        })
    }
}