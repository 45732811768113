import React from 'react';
import CssBaseLine from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles'

import { Provider } from 'react-redux'
import store from './redux'
import Routes from './routes'

import ReactGA from 'react-ga';

import moment from 'moment'
import 'moment/locale/bg'

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

moment.locale('bg')

const theme = createMuiTheme({})

ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID);

function App() {
  return (
    <React.Fragment>
      <CssBaseLine/>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Routes/>
          </ThemeProvider>
        </Provider>
      </MuiPickersUtilsProvider>
    </React.Fragment>
  );
}

export default App;
