import request from '../client'
import { ACTION_CONFIG } from './app'

const ACTION_LOAD = 'RATES/LOAD'
const ACTION_LOADING = 'RATES/LOADING'

const initState = {
    loading: true,
    list: [],
    currencies: []
}

function loadAction(state, payload) {
    const list = {};
    const currencies = {};
    payload.forEach(rate=>{
        if(!list[rate.source.code]) {
            list[rate.source.code] = {};
            list[rate.source.code][rate.source.code] = 1;
        }
        list[rate.source.code][rate.target.code] = rate.rate;
        if(!currencies[rate.source.code]) {
            currencies[rate.source.code] = rate.source;
        }
        if(!currencies[rate.target.code]) {
            currencies[rate.target.code] = rate.target;
        }
    })
    return {
        ...state,
        loading: false,
        list,
        currencies
    }
}

export default function (state = initState, { type, payload }) {
    switch (type) {
        case ACTION_LOADING:
            return {
                ...state,
                loading: true
            }
        case ACTION_LOAD:
            return loadAction(state, payload)
        case ACTION_CONFIG:
            return loadAction(state, payload.rates);
        default:
            return state;
    }
}

export function loadRates() {
    return async dispatch => {
        dispatch({
            type: ACTION_LOADING,
            payload: true
        })
        const rates = await request('/rates', {
            method: 'GET'
        });
        dispatch({
            type: ACTION_LOAD,
            payload: rates
        })
    }
}