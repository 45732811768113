import qs from 'qs';

let AUTH_TOKEN;

class HttpError extends Error {
    constructor(code, message, extras) {
        super(message);
        if (extras) {
            this.body = extras;
        }
        this.code = code;
    }
}

export function useAuthToken(token) {
    AUTH_TOKEN = token;
}

export default function request(path, { method, body, query }) {
    let headers = {};
    if(AUTH_TOKEN) {
        headers.Authorization = `Bearer ${AUTH_TOKEN}`
    }
    return fetch(`${process.env.REACT_APP_API_URL}${path}${query ? `?${qs.stringify(query)}` : ''}`, {
        method,
        body,
        headers
    }).then(async response => {
        let json;
        try {
            json = await response.json();
        } catch(e) {}
        if (!response.ok) {
            throw new HttpError(response.status, json && json.error ? json.error : response.statusText, {
                ...json
            });
        }
        return json
    })
}

let $__CACHE__ = {};

export function cachedRequest(path, { method, body, query, region }) {
    if(!$__CACHE__[region]) {
        $__CACHE__[region] = [];
    }
    let $CACHE = $__CACHE__[region];

    const cacheKey = JSON.stringify([path, method, body, query])

    let cache = $CACHE.filter(item=>item.key === cacheKey)[0]
    if(!cache) {
        cache = {
            key: cacheKey
        };
        cache.promise = new Promise(async function(resolve, reject){
            try {
                cache.fetching = true;
                const result = await request(path, {method, body, query});
                cache.fetch = false;
                resolve(result);
            } catch(e) {
                cache.fetching = false;
                $__CACHE__[region] = $CACHE.filter(item=>item.key !== cacheKey);
                reject(e)
            }
        });

        $CACHE.push(cache);

        if($CACHE.length > 10) {
            $__CACHE__[region] = $CACHE.slice(-10);
        }
    }
    return cache;
}