import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';

import app from './app'
import rates from './rates'
import facets from './facets'
import watchlist from './watchlist'

export default createStore(combineReducers({
    app,
    rates,
    facets,
    watchlist
}), applyMiddleware(thunk))